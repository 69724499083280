import React from 'react'

import { Link } from 'gatsby'

const AppLink = (props) => (
  props.to && props.to.indexOf('mailto') === -1
    ? <Link {...props}>{props.children}</Link>
    : <a href={props.to || '#'} {...props}>{props.children}</a>
)

export default AppLink
