import React from 'react'
import { cn } from '../lib/helpers'

import * as styles from './button.module.css'
import Link from './link'

const Button = ({ to, outlined, disabled, white, asLink, onClick, children }) => (
  to
    ? (
      <Link className={styles.link} to={to} onClick={onClick}>
        <div className={cn(
          styles.button,
          outlined && styles.outlined,
          white && styles.white,
          asLink && styles.asLink,
          disabled && styles.disabled
        )}
        >
          {children}
        </div>
      </Link>
    )
    : (
      <div
        onClick={disabled || onClick}
        className={cn(
          styles.button,
          outlined && styles.outlined,
          white && styles.white,
          asLink && styles.asLink,
          disabled && styles.disabled
        )}
      >
        {children}
      </div>
    )
)

export default Button
