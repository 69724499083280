import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import * as styles from './header.module.css'
import LogoIcon from './icons/logo'
import Button from './button'
import Link from './link'

const Header = ({ onHideNav, onShowNav, showNav, menues, lang, setLang }) => {
  const buttons = (buttons) => {
    return buttons && buttons.map((b, i) => (
      <Button to={b.to} outlined={b.outlined} key={i}>
        {b.caption}
      </Button>
    ))
  }
  const links = (links) => {
    return links && links.map((b, i) => (
      <li key={i}>
        <Link to={b.to} outlined={b.outlined}>{b.caption}</Link>
      </li>
    ))
  }

  const langIndex = menues.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Link to={lang === 'rus' ? '/' : '/en'} title='home'>
          <LogoIcon />
        </Link>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol='hamburger' />
        </button>

        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            {menues[currentLang].headerLinks && links(menues[currentLang].headerLinks)}

          {/*  <li onClick={() => setLang(lang === 'rus' ? 'eng' : 'rus')}>
              <Link style={{ cursor: 'pointer' }}>
                {lang === 'rus' ? 'EN' : 'RU'}
              </Link>
            </li>*/}
          </ul>

          <div className={styles.buttons}>
            {menues[currentLang].headerButtons && buttons(menues[currentLang].headerButtons)}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
