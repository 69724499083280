import React from 'react'
import { cn } from '../lib/helpers'

import * as styles from './footer.module.css'
import LogoIcon from './icons/logo'
import Link from './link'

const Footer = ({ menues, lang }) => {
  const address = (address) => {
    return address && address.map((b, i) => (
      <span key={i}>{b}<br /></span>
    ))
  }
  const links = (links) => {
    return links && links.map((b, i) => (
      <div key={i}>
        <Link to={b.to} outlined={b.outlined}>{b.caption}</Link>
      </div>
    ))
  }
  const secondLinks = (links) => {
    return links && links.map((b, i) => (
      <div key={i}>
        <Link to={b.to} className={cn(b.outlined && styles.colored)}>{b.caption}</Link>
      </div>
    ))
  }

  const langIndex = menues.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0

  const year = new Date().getFullYear()

  return (
    <footer className={styles.root}>
      <div className={styles.wrapper}>
        <div className={cn(styles.column)}>
          <Link to='/' className={styles.logo} title='home'>
            <LogoIcon />
          </Link>
        </div>

        <div className={cn(styles.column)}>
          {menues[currentLang].footerLinks && links(menues[currentLang].footerLinks)}
        </div>

        <div className={cn(styles.column)}>
          {menues[currentLang].footerLinksSecondary && secondLinks(menues[currentLang].footerLinksSecondary)}
        </div>

        <div className={cn(styles.column)}>
          {menues[currentLang].address && address(menues[currentLang].address)}
          <br />
          © Roowix, {year}
        </div>
      </div>
    </footer>
  )
}
export default Footer
