import { graphql, StaticQuery } from 'gatsby'
import { mapEdgesToNodes, getPageLang } from '../lib/helpers'
import React, { useState, useEffect } from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import Gdpr from '../components/gdpr'
import Seo from '../components/seo'

import '../styles/layout.css'
import * as styles from './layout.module.css'

// поддерживаемые языки
const LANGUAGES = {
  RUS: 'eng',
  ENG: 'rus'
}
const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      script
      noscript
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
      country
    }
    gdpr: allSanityGdpr(limit: 6) {
      edges {
        node {
          title
          text
          captionOk
          captionNotOk
        }
      }
    }
    menues: allSanityMenues(limit: 6) {
      edges {
        node {
          title
          address
          logo {
            asset {
              _id
            }
          }
          headerLinks {
            caption
            to
            outlined
          }
          headerButtons {
            caption
            to
            outlined
          }
          footerLinks {
            caption
            to
            outlined
          }
          footerLinksSecondary {
            caption
            to
            outlined
          }
        }
      }
    }
  }
`

// враппер для пробрасывания языка во все дочерние элементы
const LangWrapper = (props) => (
  React.Children.map(props.children, child => (
    React.cloneElement(child, {
      lang: props.lang
    }))
  )
)

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)

  const headerFuncs = {
    showNav,
    onShowNav: () => setShowNav(true),
    onHideNav: () => setShowNav(false)
  }

  const lang = getPageLang(props.pageContext)

  const setLang = (newLang) => {
    if (newLang) {
      localStorage.setItem('lang', newLang)
    }

    if (newLang === LANGUAGES.RUS) {
      window.location = '/en' + window.location.pathname
    } else {
      window.location = window.location.pathname.split('/en').join('')
    }
  }

  useEffect(() => () => {
    // Event snippet for Подписка conversion page
    if (typeof window !== 'undefined') {
      window.gtag('event', 'conversion', { send_to: 'AW-10829045827/cSWPCPSGtpEDEMPA2Kso' })
    }
  }, [])

  return (
    <StaticQuery
      query={query}
      render={data => {
        const site = (data || {}).site

        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }

        const menues = (data || {}).menues
          ? mapEdgesToNodes(data.menues)
          : []

        const gdpr = (data || {}).gdpr
          ? mapEdgesToNodes(data.gdpr)
          : []

        return (
          <LangWrapper lang={lang}>
            <Seo
              title={props.title}
              description={site.description}
              keywords={site.keywords}
              script={site.script}
              noscript={site.noscript}
            />
            <Header {...headerFuncs} menues={menues} setLang={setLang} />
            <Gdpr data={gdpr} />
            <div className={styles.content}>
              {React.Children.map(props.children, child => React.cloneElement(child, { lang, setLang }))}
            </div>
            <Footer menues={menues} />
          </LangWrapper>
        )
      }}
    />
  )
}

export default LayoutContainer
