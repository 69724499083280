import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'

const detailsQuery = graphql`
  query SEOQuery {
    site: allSanitySiteSettings(limit: 6) {
      edges {
        node {
          title
          pageTitle
          description
          keywords
          script
          noscript
        }
      }
    }
  }
`

function SEO ({ description, lang = 'rus', meta, keywords = [], title, script = [], noscript = [] }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.site) {
          return
        }

        const scritps = script.map((s, i) => (
          <script
            type="javascript"
            key={i}
            dangerouslySetInnerHTML={{
              __html: s,
            }}
          />
        ))

        const noscritps = noscript.map((s, i) => (
          <noscript
            key={i}
            dangerouslySetInnerHTML={{
              __html: s,
            }}
          />
        ))

        const site = (data || {}).site
          ? mapEdgesToNodes(data.site)
          : []

        const langIndex = site.findIndex(g => g.title === lang)
        const currentLang = langIndex !== -1 ? langIndex : 0

        const metaDescription = description || site[currentLang].description
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title || site[currentLang].pageTitle}
            // titleTemplate='%s'
            titleTemplate={title ? `%s | ${site[currentLang].pageTitle}` : '%s'}
            script={script.map(s => ({content: s}))}
            noscript={noscript.map(s => ({content: s}))}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                name: 'google-site-verification',
                content: 'KXijRp7R-XTX-WZlVKHMXSQBT05BsXnIMrMYjBNIeMA'
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', ')
                  }
                  : []
              )
              .concat(meta)}
          >
            {scritps && scritps}
            {noscritps && noscritps}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'ru',
  meta: [],
  keywords: [],
  script: [],
  noscript: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  script: PropTypes.arrayOf(PropTypes.string),
  noscript: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
}

export default SEO
