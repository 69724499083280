import React from 'react'

import * as styles from './container.module.css'

const Container = ({ children, lang, setLang }) => {
  return (
    <div className={styles.root}>
      {React.Children.map(children, child => React.cloneElement(child, { lang, setLang }))}
    </div>
  )
}

export default Container
