import React, { useState, useEffect } from 'react'

import * as styles from './gdpr.module.css'
import Button from './button'

import { cn } from '../lib/helpers'

// возможные статусы GDPR, сводим все значения к ним
const GDPR_STATUSES = {
  NONE: 'none',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected'
}

const GDPR = ({ data, lang }) => {
  const [policy, setPolicy] = useState('')

  useEffect(() => {
    const policy = localStorage.getItem('policy')

    // любое некорректное значение обозначаем как NONE
    // для избежания мерцания при загрузке страницы, когда состояние ещё не получено
    if (Object.values(GDPR_STATUSES).indexOf(policy) < 0) {
      localStorage.setItem('policy', GDPR_STATUSES.NONE)
      setPolicy(GDPR_STATUSES.NONE)
      return
    }

    setPolicy(policy)
  }, [])

  useEffect(() => {
    if (policy) {
      localStorage.setItem('policy', policy)
    }
  }, [policy])

  const gdpr = data || []
  const langIndex = gdpr.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0

  return (
    <div className={cn(styles.container, policy === GDPR_STATUSES.NONE && styles.show)}>
      <div className={styles.wrapper}>
        <div>
          <span>
            {gdpr[currentLang].text || `This website uses cookies and other technologies to help you
              in navigation, as well as provide a better user experience and analyze the use of the product.`}
          </span>
        </div>
        <div className={styles.buttons}>
          <Button onClick={() => setPolicy(GDPR_STATUSES.ACCEPTED)}>{gdpr[currentLang].captionOk || 'accept'}</Button>
          <Button outlined onClick={() => setPolicy(GDPR_STATUSES.REJECTED)}>{gdpr[currentLang].captionNotOk || 'dismiss'}</Button>
        </div>
      </div>
    </div>
  )
}

export default GDPR
